import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import TopBar from "../components/Layout/TopBar";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import Footer from "../components/Layout/Footer";
import ReusableTiles from "../components/ReusableTiles";
import ReactPlayer from "react-player";
import SEO from "../components/seo";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default function Competition() {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { regex: "/comp/hamper-main.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
  <Helmet>
  <script src="https://cdn.greenvideo.io/players/gv.js" data-license-key="6504a252e43825272f33264dd5fa5666c7a2987b" async></script>
      { /* <SEO title="Welcome" keywords={[`great`, `british`, `dogs`]} /> */ }
      <SEO
        title="greatbritishdogs.co.uk | Welcome"
        keywords={[`great`, `british`, `dogs`]}
      />
    </Helmet>
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold   `}
              }

              .accent {
                ${tw`text-accent`}
              }
              a {
                ${tw`text-primary no-underline font-bold `}
              }
            `}
          >
            <div className="text-center"><div className="font-bench  text-white text-xl mx-auto bg-primary px-4 py-1 uppercase leading-none inline-block rounded-lg my-3">Welcome</div></div>
            <div className="form-title">
          <h1 css={css`
                color: #ae456c !important; margin: 0.5em auto 0.5em auto !important; font-size: 2rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">Welcome to Great British Dogs</h1>

          <h2 className="font-sans font-bold text-xl text-primary" css={css`
                margin-bottom: 1em !important; margin-top: 1em !important; font-size: 1.1em !important;`}>
            {" "}
            SPONSORED BY WINALOT<sup>®</sup>
          </h2>
        </div>
        <green-video content-id="d8b474cefc9e62e8fe6ec00df285a5ccaaf27fcc" embed-id="PDwvpKNT"></green-video>
        <p className="my-4">You’ve found Great British Dogs, the home of uplifting, heart-warming stories of everyday greatness in the nation’s canine companions – all from genuine dog owners. </p>
        <p className="my-4">
              The dogs featured on this site may come in all shapes and sizes, 
              and the stories themselves cover everything from the  
              <Link to="/brave-loyal"> brave</Link> and the  <Link className="font-bold" to="/funny">funny</Link>, 
              to <Link to="/rescue-dogs">rescue dogs</Link> and even <Link to="/support-dogs">support dogs</Link>. But all have one thing in common: the profound and touching love that dogs can give their humans, and vice versa.
            </p>
            <p className="my-4">
            Great British Dogs is sponsored by <a href="https://bit.ly/3eRimzY" target="_blank">Winalot®</a> , which has been feeding greatness in the UK’s dogs since 1927. By entering your story on the Great British Dogs site, and uploading a picture, you’ll have the chance to win some brilliant Great British Dogs goodies, 
            including Winalot® pouches. And one lucky winner will be selected by our panel of judges to win a £1000 stay at the dog-friendly <a href="https://bit.ly/3UICIjD" target="_blank">Double Red Duke Hotel</a> in Oxfordshire.
            </p>
            <p className="my-4">
            Great British Dogs is now in its fifth full year: to date we've received more than 7,300 stories from dog owners and you can read over 500 of them in our <Link to="/class-of-2019-23">class of 19/23</Link> section. {/*Now, for the 2020/21 campaign, we’ve joined forces with Paratriathlete Lauren Steadman – along with her German Shepherd Meric Belle and Meric’s brother Robin. You can <Link to="/meet-lauren-meric-robin">read their story here</Link>.*/}
            </p>
            <p></p>
            <p><br></br></p>
          </div>
        </div>
        {/*<div className="text-center">
          <Link
            to="/submit-story-2024"
            className="bg-accent font-bench text-2xl leading-none inline-flex items-center justify-center py-2 mb-12  text-white rounded-full relative "
            style={{
              width:"290px",
              height:"50px",
              
            }}>
            SHARE YOUR STORY TO WIN {" "}
            <MdKeyboardArrowRight className="absolute right-0   mr-8v2" />
          </Link>
        </div>*/}
      </div>
      <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
      </div>
      <Footer />
    </>
  );
}
